<template>
  <section id="Testimonios" class="testimonies" data-aos="fade-down">
    <div class="__content mb-0">
      <div class="w-100 d-flex justify-content-start ms-8">
        <h2>
          <span style="color: #14993c"> Español </span>
        </h2>
      </div>

      <input
        class="form-control form-control-lg wet-form fw-bold text-center my-2"
        type="text"
        v-model="dataES.title"
      />
      <div class="___testimonies-container mb-0">
        <div
          class="tarjeta-equipo p-3"
          v-for="(testimony, i) in dataES.testimonies"
          :key="testimony.id"
        >
          <div
            class="d-flex justify-content-center container-equipo-experiencia"
          >
            <img
              class="img-equipo-experiencia"
              :src="require(`../../assets/${testimony.img}`)"
              :alt="`Imagen de testiomino de ${testimony.name}`"
              loading="lazy"
            />
          </div>
          <div class="size-nombre-testimonial">
            <a
              :href="testimony.linkedIn"
              target="blank"
              style="text-decoration: none; color: #000"
            >
              <h3 class="sub-titulo fw-bold text-center">
                <img
                  height="18"
                  src="@/assets/img/icono_linkedin.svg"
                  style="margin-bottom: 4px; margin-right: 6px"
                  alt="Icono LinkedIn"
                  loading="lazy"
                />

                <input
                  class="form-control form-control-lg wet-form fw-bold text-center my-2"
                  type="text"
                  v-model="testimony.name"
                />
              </h3>
            </a>
            <input
              class="form-control wet-form text-center body1 my-2"
              type="text"
              v-model="testimony.position"
            />
            <textarea
              class="form-control wet-form text-center body2 mb-0"
              rows="5"
              type="text"
              v-model="testimony.preview"
            />
          </div>
          <div class="accordion accordion-flush" :id="`accordionExample${i}`">
            <div class="">
              <h2 class="accordion-header" :id="`heading${i}`">
                <button
                  class="accordion-button my-3 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#collapse${testimony.htmlId}`"
                  aria-expanded="true"
                  :aria-controls="`collapse${testimony.htmlId}`"
                >
                  <!--                  <i class="bi bi-caret-down"></i>-->
                </button>
              </h2>
              <div
                :id="`collapse${testimony.htmlId}`"
                class="accordion-collapse collapse"
                :aria-labelledby="`heading${testimony.htmlId}`"
                :data-bs-parent="`accordionExample${i}`"
              >
                <div class="accordion-body">
                  <ckeditor
                    :editor="editor"
                    v-model="testimony.content"
                    :config="editorConfig"
                  ></ckeditor>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 d-flex justify-content-start ms-8">
          <button
            class="btn btn-success btn-lg mt-3 mb-3"
            @click="syncContent('ES')"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>

    <div class="w-100">
      <hr class="pill my-5" />
    </div>

    <div class="__content">
      <div class="w-100 d-flex justify-content-start ms-8">
        <h2>
          <span style="color: #14993c"> English </span>
        </h2>
      </div>

      <input
        class="form-control form-control-lg wet-form fw-bold text-center my-2"
        type="text"
        v-model="dataEN.title"
      />
      <div class="___testimonies-container">
        <div
          class="tarjeta-equipo p-3"
          v-for="(testimony, i) in dataEN.testimonies"
          :key="testimony.id"
        >
          <div
            class="d-flex justify-content-center container-equipo-experiencia"
          >
            <img
              class="img-equipo-experiencia"
              :src="require(`../../assets/${testimony.img}`)"
              :alt="`Imagen de testiomino de ${testimony.name}`"
              loading="lazy"
            />
          </div>
          <div class="size-nombre-testimonial">
            <a
              :href="testimony.linkedIn"
              target="blank"
              style="text-decoration: none; color: #000"
            >
              <h3 class="sub-titulo fw-bold text-center">
                <img
                  height="18"
                  src="@/assets/img/icono_linkedin.svg"
                  style="margin-bottom: 4px; margin-right: 6px"
                  alt="Icono LinkedIn"
                  loading="lazy"
                />

                <input
                  class="form-control form-control-lg wet-form fw-bold text-center my-2"
                  type="text"
                  v-model="testimony.name"
                />
              </h3>
            </a>
            <input
              class="form-control wet-form text-center body1 my-2"
              type="text"
              v-model="testimony.position"
            />
            <textarea
              class="form-control wet-form text-center body2 mb-0"
              rows="5"
              type="text"
              v-model="testimony.preview"
            />
          </div>
          <div class="accordion accordion-flush" :id="`accordionExample${i}`">
            <div class="">
              <h2 class="accordion-header" :id="`heading${i}`">
                <button
                  class="accordion-button my-3 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#collapse${testimony.htmlId}`"
                  aria-expanded="true"
                  :aria-controls="`collapse${testimony.htmlId}`"
                >
                  <!--                  <i class="bi bi-caret-down"></i>-->
                </button>
              </h2>
              <div
                :id="`collapse${testimony.htmlId}`"
                class="accordion-collapse collapse"
                :aria-labelledby="`heading${testimony.htmlId}`"
                :data-bs-parent="`accordionExample${i}`"
              >
                <div class="accordion-body">
                  <ckeditor
                    :editor="editor"
                    v-model="testimony.content"
                    :config="editorConfig"
                  ></ckeditor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 d-flex justify-content-start ms-8">
        <button
          class="btn btn-success btn-lg mt-3 mb-3"
          @click="syncContent('EN')"
        >
          Save
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getSection, putSection } from "../../helpers/contenido";

export default {
  name: "Testimonios",
  data() {
    return {
      dataES: {
        title: "Testimonios",
        testimonies: [
          {
            id: 1,
            htmlId: "cristobal",
            img: "img/cristobal.webp",
            name: "Cristóbal de Solminhiac",
            position: "CEO y Cofundador | Autofact",
            content: `
            <p>En Autofact hemos seguido el programa diseñado por WETFOREST paso a paso. Este nos ha permitido profesionalizar el negocio que creamos. Desde lo general a lo particular en temas de alineamiento de la empresa, finanzas, organización, estrategia y ventas entre otros. En 2 años de asesoría hemos cambiado la forma de organización y gestión de AUTOFACT, lo que me permite decir hoy con propiedad que estamos preparados para crecer con fuerza en los próximos años. Profesionalizamos la forma en la que operamos y nos organizamos. Este paso fue clave para sentir seguridad operativa y financiera, así poder dedicar tiempo y recursos a temas de nuestro interés: innovación y crecimiento. Entre sus numerosas virtudes destaco: compromiso y respeto con tu negocio y gran experiencia en el desarrollo y operación de empresas. Estas virtudes aplicadas de manera concreta a través de reuniones semanales y seguimiento a cada iniciativa acordada. En mis 8 años de emprendedor es el programa de apoyo con mayor impacto que he tenido.</p>
          `,
            preview:
              "Strategic advisors con experiencia empresarial que se comprometen con tu negocio.",
          },
          {
            id: 2,
            htmlId: "ricardo",
            img: "img/ricardo.webp",
            name: "Ricardo Navarro",
            position: "CEO y Cofundador |TocBiometrics",
            content: `
            <p>El primer Programa que seguimos que hoy ofrece WETFOREST nos permitió ajustar nuestro modelo de negocio de una modalidad eminentemente técnica a un modelo centrado en el cliente y en sus beneficios. Esto nos permitió identificar claramente las aplicaciones reales y cubicar el potencial de cada cliente, de cada segmento y de todo el mercado, y escalar mas fácilmente a los que toman las decisiones. Tomamos un Programa en una segunda oportunidad y nos permitió aclarar mejor la competencia y los diversos canales de venta posibles tanto en Chile, Latam y resto del mundo y definir mejor nuestra estrategia comercial. Actualmente nos hemos integrado a la comunidad de WETFOREST manteniendo una estrecha relación de colaboración mutua.</p>
          `,
            preview:
              "Primero tomamos sus programas, hoy estamos integrados a su comunidad..",
          },
          {
            id: 3,
            htmlId: "gonzalo",
            img: "img/gonzalo.webp",
            name: "Gonzalo Heredia",
            position: "Gerente General y Cofundador | DreamIT",
            content: `
            <p>Hemos trabajado durante más de un año cada semana con un Consultor Senior de WETFOREST y ello nos ha permitido definir aspectos muy importantes de nuestra empresa partiendo por la dirección estratégica y la definición de la estructura de la organización indispensable para haber logrado un importante incremento en las ventas y al mismo tiempo un estricto control administrativo y financiero que nos ha garantizado un crecimiento sustentable. Como resultado de lo anterior ya contamos con procesos definidos y métricas en las áreas Comercial, Operaciones y Servicio al Cliente lo que ha liberado tiempo a la Gerencia para dedicarse no solo a aspectos operacionales de corto plazo, sino que disponer de una parte de su tiempo a pensar en la Estrategia de Productos y Servicios que satisfagan la creciente demanda de los Clientes, como asimismo en la expansión a otro países de Latinoamérica. Hoy la empresa cuenta con un Directorio que se reúne mensualmente para recibir información estructurada que entrega la Gerencia y es una instancia de discusión de temas contingentes de la empresa, el mercado y la industria con una visión estratégica de largo plazo.</p>
          `,
            preview:
              "Un programa de apoyo estratégico y operacional que entrega resultados.",
          },
          {
            id: 4,
            htmlId: "carla",
            img: "img/carla.webp",
            name: "Carla Rodríguez Viejo",
            position: "CEO y Fundador | TodoVa",
            content: `
            <p>Estuvimos trabajando intensivamente bajo el programa de WETFOREST y la verdad es que nos dio un giro completo a nuestra empresa, otorgando orden, direccionamiento y por sobretodo bases sólidas que unieron al equipo hacia un objetivo común. El equipo de WETFOREST destaca por su profesionalismo, entrega, apoyo y paciencia al momento de entregar y compartir su experiencia. Cuentan con conocimientos sólidos y además son un equipo multidisciplinario por lo que son capaces de abordar cualquier área para trabajarla en profundidad. Nos ayudaron a sentar las bases para dar el gran paso que estábamos buscando y así fue como en enero 2020, nuestra empresa se vendió a CHAZKI con exitosas operaciones en Perú, México, Argentina y ahora Chile. ¡Gracias por tanto!</p>
          `,
            preview:
              "Apoyo y crecimiento para Todova, tanto a nivel profesional como humano.",
          },
          {
            id: 5,
            htmlId: "gabriel",
            img: "img/gabriel.webp",
            name: "Gabriel Monroy",
            position: "CEO y Cofundador | Colektia",
            content: `
            <p>WETFOREST ha sido un partner fundamental en el escalamiento y profesionalización de Colektia, cuentan con una metodología de acompañamiento muy eficiente, que nos ha permitido semana a semana dar orientación estratégica a la compañía para alcanzar objetivos que hace meses lucían muy complejos. Además sus strategic advisors cuentan con una importante experiencia que nos ha permitido tomar decisiones acertadas en la dirección de la compañía, viéndose reflejados en el crecimiento exponencial de nuestras ventas y alta retención de nuestros clientes. Destaca por su calidad humana, altamente comprometidos con lo que hacen, y genuinamente comprometidos con el crecimiento de Colektia, lo cual destaca de cualquier programa similar en el mercado.</p>
          `,
            preview:
              "Profesionales de muy alto perfil y calidad humana intachable.",
          },
          {
            id: 6,
            htmlId: "francisco",
            img: "img/francisco.webp",
            name: "Francisco, Fernando y Felipe Alcalde",
            position: "Cofundadores | 3F",
            content: `
            <p>¿Qué diferencia el programa de WETFOREST a otros del mercado? Simple: disrupción, inmunidad y oportunidad. Por un lado, su Programa Fundacional basado en el conocimiento, la experiencia, la pasión y el compromiso del equipo, unido con su adicción a generar valor, ha desafiado nuestra visión interna, revelado nuestro valores y reinventado nuestra estructura humana y de procesos, permitiéndonos pivotear nuestra estrategia e incrementar valor en todas nuestras áreas, pero principalmente en nuestros clientes. Por otro, establecer objetivos audaces a largo plazo, inversión en herramientas de digitalización y automatización, diversificación, generación de marca y estabilidad financiera, nos ha dejado establecer las bases para pasar al siguiente nivel, estar inmune a las amenazas que traerá una “nueva normalidad” post Covid-19 y sobre todo, estar preparado para distinguir sus oportunidades. Recomendamos sinceramente al equipo de WETFOREST, por el impacto positivo que han tenido en nuestra empresa y en nosotros, como profesionales y personas. Gracias por fundirse con este Equipo y mirar en conjunto al 2030!</p>
          `,
            preview:
              "Wetforest: impactando el valor, inmunidad y oportunidad de 3F.",
          },
          {
            id: 7,
            htmlId: "tomas",
            img: "img/tomas.webp",
            name: "Tomás Almarza",
            position: "CEO y Cofundador | OOH Publicidad",
            content: `
            <p>WETFOREST ha sido esencial en el proceso de profesionalización de nuestra empresa, logrando alinear estratégicamente a toda la organización hacia un propósito en común, optimizar el proceso de gestión financiera, potenciar la generación de nuevas líneas de negocios y de lograr mejores resultados comerciales a través de mercados segmentados. La metodología de trabajo semana a semana y la experiencia empresarial y práctica del equipo de WETFOREST nos ha impulsado a alcanzar nuestros objetivos de escalamiento y profesionalización. Nos hemos sentido muy acompañados con sus grandes valores entregándonos un comprometido y valioso apoyo tanto en tiempos normales como de crisis.</p>
          `,
            preview:
              "WETFOREST ha sido esencial en el proceso de profesionalización de nuestra empresa.",
          },
          {
            id: 8,
            htmlId: "tomas2",
            img: "img/tomas-2.webp",
            name: "Tomás Almarza",
            position: "CEO y Cofundador | OOH Publicidad",
            content: `
            <p>WETFOREST ha sido para nosotros la asesoría estratégica clave para desarrollar las fundaciones que nuestra empresa necesita para dar los siguientes pasos. Desde la estructura organizacional hasta las estrategias comerciales, pasando por finanzas, RRHH, innovación e inversión, cada etapa desarrollada como un pilar fundamental. Esta fórmula nos permitió ordenar la operación luego de lograr nuestra primera ronda de inversión y definir la estrategia para enfrentar de mejor manera un crecimiento acelerado y los futuros levantamientos de capital. Además de su profesionalismo y actualizada experiencia, destaco el compromiso e interés genuino con que WETFOREST ha trabajado en nuestro proyecto, involucrándose profundamente no solo en los procesos empresariales, sino también aportando una mirada humana a la forma de hacer negocios.</p>
          `,
            preview:
              "Experiencia e innovación para prepararnos en el paso de startup exitosa a gran empresa.",
          },
        ],
      },
      dataEN: {
        title: "Testimonios",
        testimonies: [
          {
            id: 1,
            htmlId: "cristobal",
            img: "img/cristobal.webp",
            name: "Cristóbal de Solminhiac",
            position: "CEO y Cofundador | Autofact",
            content: `
            <p>En Autofact hemos seguido el programa diseñado por WETFOREST paso a paso. Este nos ha permitido profesionalizar el negocio que creamos. Desde lo general a lo particular en temas de alineamiento de la empresa, finanzas, organización, estrategia y ventas entre otros. En 2 años de asesoría hemos cambiado la forma de organización y gestión de AUTOFACT, lo que me permite decir hoy con propiedad que estamos preparados para crecer con fuerza en los próximos años. Profesionalizamos la forma en la que operamos y nos organizamos. Este paso fue clave para sentir seguridad operativa y financiera, así poder dedicar tiempo y recursos a temas de nuestro interés: innovación y crecimiento. Entre sus numerosas virtudes destaco: compromiso y respeto con tu negocio y gran experiencia en el desarrollo y operación de empresas. Estas virtudes aplicadas de manera concreta a través de reuniones semanales y seguimiento a cada iniciativa acordada. En mis 8 años de emprendedor es el programa de apoyo con mayor impacto que he tenido.</p>
          `,
            preview:
              "Strategic advisors con experiencia empresarial que se comprometen con tu negocio.",
          },
          {
            id: 2,
            htmlId: "ricardo",
            img: "img/ricardo.webp",
            name: "Ricardo Navarro",
            position: "CEO y Cofundador |TocBiometrics",
            content: `
            <p>El primer Programa que seguimos que hoy ofrece WETFOREST nos permitió ajustar nuestro modelo de negocio de una modalidad eminentemente técnica a un modelo centrado en el cliente y en sus beneficios. Esto nos permitió identificar claramente las aplicaciones reales y cubicar el potencial de cada cliente, de cada segmento y de todo el mercado, y escalar mas fácilmente a los que toman las decisiones. Tomamos un Programa en una segunda oportunidad y nos permitió aclarar mejor la competencia y los diversos canales de venta posibles tanto en Chile, Latam y resto del mundo y definir mejor nuestra estrategia comercial. Actualmente nos hemos integrado a la comunidad de WETFOREST manteniendo una estrecha relación de colaboración mutua.</p>
          `,
            preview:
              "Primero tomamos sus programas, hoy estamos integrados a su comunidad..",
          },
          {
            id: 3,
            htmlId: "gonzalo",
            img: "img/gonzalo.webp",
            name: "Gonzalo Heredia",
            position: "Gerente General y Cofundador | DreamIT",
            content: `
            <p>Hemos trabajado durante más de un año cada semana con un Consultor Senior de WETFOREST y ello nos ha permitido definir aspectos muy importantes de nuestra empresa partiendo por la dirección estratégica y la definición de la estructura de la organización indispensable para haber logrado un importante incremento en las ventas y al mismo tiempo un estricto control administrativo y financiero que nos ha garantizado un crecimiento sustentable. Como resultado de lo anterior ya contamos con procesos definidos y métricas en las áreas Comercial, Operaciones y Servicio al Cliente lo que ha liberado tiempo a la Gerencia para dedicarse no solo a aspectos operacionales de corto plazo, sino que disponer de una parte de su tiempo a pensar en la Estrategia de Productos y Servicios que satisfagan la creciente demanda de los Clientes, como asimismo en la expansión a otro países de Latinoamérica. Hoy la empresa cuenta con un Directorio que se reúne mensualmente para recibir información estructurada que entrega la Gerencia y es una instancia de discusión de temas contingentes de la empresa, el mercado y la industria con una visión estratégica de largo plazo.</p>
          `,
            preview:
              "Un programa de apoyo estratégico y operacional que entrega resultados.",
          },
          {
            id: 4,
            htmlId: "carla",
            img: "img/carla.webp",
            name: "Carla Rodríguez Viejo",
            position: "CEO y Fundador | TodoVa",
            content: `
            <p>Estuvimos trabajando intensivamente bajo el programa de WETFOREST y la verdad es que nos dio un giro completo a nuestra empresa, otorgando orden, direccionamiento y por sobretodo bases sólidas que unieron al equipo hacia un objetivo común. El equipo de WETFOREST destaca por su profesionalismo, entrega, apoyo y paciencia al momento de entregar y compartir su experiencia. Cuentan con conocimientos sólidos y además son un equipo multidisciplinario por lo que son capaces de abordar cualquier área para trabajarla en profundidad. Nos ayudaron a sentar las bases para dar el gran paso que estábamos buscando y así fue como en enero 2020, nuestra empresa se vendió a CHAZKI con exitosas operaciones en Perú, México, Argentina y ahora Chile. ¡Gracias por tanto!</p>
          `,
            preview:
              "Apoyo y crecimiento para Todova, tanto a nivel profesional como humano.",
          },
          {
            id: 5,
            htmlId: "gabriel",
            img: "img/gabriel.webp",
            name: "Gabriel Monroy",
            position: "CEO y Cofundador | Colektia",
            content: `
            <p>WETFOREST ha sido un partner fundamental en el escalamiento y profesionalización de Colektia, cuentan con una metodología de acompañamiento muy eficiente, que nos ha permitido semana a semana dar orientación estratégica a la compañía para alcanzar objetivos que hace meses lucían muy complejos. Además sus strategic advisors cuentan con una importante experiencia que nos ha permitido tomar decisiones acertadas en la dirección de la compañía, viéndose reflejados en el crecimiento exponencial de nuestras ventas y alta retención de nuestros clientes. Destaca por su calidad humana, altamente comprometidos con lo que hacen, y genuinamente comprometidos con el crecimiento de Colektia, lo cual destaca de cualquier programa similar en el mercado.</p>
          `,
            preview:
              "Profesionales de muy alto perfil y calidad humana intachable.",
          },
          {
            id: 6,
            htmlId: "francisco",
            img: "img/francisco.webp",
            name: "Francisco, Fernando y Felipe Alcalde",
            position: "Cofundadores | 3F",
            content: `
            <p>¿Qué diferencia el programa de WETFOREST a otros del mercado? Simple: disrupción, inmunidad y oportunidad. Por un lado, su Programa Fundacional basado en el conocimiento, la experiencia, la pasión y el compromiso del equipo, unido con su adicción a generar valor, ha desafiado nuestra visión interna, revelado nuestro valores y reinventado nuestra estructura humana y de procesos, permitiéndonos pivotear nuestra estrategia e incrementar valor en todas nuestras áreas, pero principalmente en nuestros clientes. Por otro, establecer objetivos audaces a largo plazo, inversión en herramientas de digitalización y automatización, diversificación, generación de marca y estabilidad financiera, nos ha dejado establecer las bases para pasar al siguiente nivel, estar inmune a las amenazas que traerá una “nueva normalidad” post Covid-19 y sobre todo, estar preparado para distinguir sus oportunidades. Recomendamos sinceramente al equipo de WETFOREST, por el impacto positivo que han tenido en nuestra empresa y en nosotros, como profesionales y personas. Gracias por fundirse con este Equipo y mirar en conjunto al 2030!</p>
          `,
            preview:
              "Wetforest: impactando el valor, inmunidad y oportunidad de 3F.",
          },
          {
            id: 7,
            htmlId: "tomas",
            img: "img/tomas.webp",
            name: "Tomás Almarza",
            position: "CEO y Cofundador | OOH Publicidad",
            content: `
            <p>WETFOREST ha sido esencial en el proceso de profesionalización de nuestra empresa, logrando alinear estratégicamente a toda la organización hacia un propósito en común, optimizar el proceso de gestión financiera, potenciar la generación de nuevas líneas de negocios y de lograr mejores resultados comerciales a través de mercados segmentados. La metodología de trabajo semana a semana y la experiencia empresarial y práctica del equipo de WETFOREST nos ha impulsado a alcanzar nuestros objetivos de escalamiento y profesionalización. Nos hemos sentido muy acompañados con sus grandes valores entregándonos un comprometido y valioso apoyo tanto en tiempos normales como de crisis.</p>
          `,
            preview:
              "WETFOREST ha sido esencial en el proceso de profesionalización de nuestra empresa.",
          },
          {
            id: 8,
            htmlId: "tomas2",
            img: "img/tomas-2.webp",
            name: "Tomás Almarza",
            position: "CEO y Cofundador | OOH Publicidad",
            content: `
            <p>WETFOREST ha sido para nosotros la asesoría estratégica clave para desarrollar las fundaciones que nuestra empresa necesita para dar los siguientes pasos. Desde la estructura organizacional hasta las estrategias comerciales, pasando por finanzas, RRHH, innovación e inversión, cada etapa desarrollada como un pilar fundamental. Esta fórmula nos permitió ordenar la operación luego de lograr nuestra primera ronda de inversión y definir la estrategia para enfrentar de mejor manera un crecimiento acelerado y los futuros levantamientos de capital. Además de su profesionalismo y actualizada experiencia, destaco el compromiso e interés genuino con que WETFOREST ha trabajado en nuestro proyecto, involucrándose profundamente no solo en los procesos empresariales, sino también aportando una mirada humana a la forma de hacer negocios.</p>
          `,
            preview:
              "Experiencia e innovación para prepararnos en el paso de startup exitosa a gran empresa.",
          },
        ],
      },

      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
      },
    };
  },
  methods: {
    async fetchContent() {
      const section = await getSection("testimonies");
      const sectionES = section.filter((section) => section.language == "ES");
      const sectionEN = section.filter((section) => section.language == "EN");

      this.sectionES = sectionES;
      this.sectionEN = sectionEN;

      this.dataES = sectionES[0].components;

      this.dataEN = sectionEN[0].components;
    },
    async syncContent(lan) {
      if (lan === "ES") {
        const content = {
          id: this.sectionES[0]._id,
          section: "testimonies",
          components: this.dataES,
          language: "ES",
        };
        await putSection(content);
      } else {
        const content = {
          id: this.sectionEN[0]._id,
          section: "testimonies",
          components: this.dataEN,
          language: "EN",
        };
        await putSection(content);
      }
    },
  },
  watch: {},
  mounted() {
    this.fetchContent();
  },
};
</script>

<style scoped lang="scss">
hr.pill {
  height: 0px;
  margin: auto auto;
  border-radius: 2px;
  color: #14993c !important;
  border: 2px solid currentColor;
  width: 80%;
}

.testimonies {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px;

  .__content {
    width: 100%;
    max-width: 1366px;
    position: relative;
  }

  .__content .___testimonies-container {
    display: grid;
    grid-gap: 144px 24px;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    margin: 125px 0;
  }
}

/* cards*/
quipo {
  position: relative;
}

.container-wetforest {
  padding-left: 8%;
  padding-right: 8%;
}

.sub-titulo {
  color: #414141;
  font-family: "InterMedium";
  font-size: 1.5rem;
  text-align: left;
}

.titulo {
  color: #414141;
  font-family: "GentiumBold";
  font-size: 3rem;
  margin-bottom: 5rem;
}

.tarjeta-equipo {
  background-color: #fff;
  border-radius: 6px;
  min-height: 340px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #cae6d3;
  box-shadow: 0px 3px 6px #14993c65;
  height: fit-content;
  /*padding-bottom: 1rem;*/
  /*padding-top: 5rem;*/
  /*padding-left: 1rem;*/
  /*padding-right: 1rem;*/
  //max-height: 650px;
  position: relative;
}

.column {
  margin-bottom: 5rem;
}

.container-equipo-experiencia {
  margin-top: -7rem;
  /*position: absolute;*/
  /*left: 50%;*/
  /*top: -70px;*/
}

.img-equipo-experiencia {
  width: 140px;
  /*left: -50%;*/
  position: relative;
}

.size-nombre-testimonial {
  min-height: 70px;
}

.accordion {
  overflow: hidden;
  transition: height 0.35s ease;
}

.tarjeta-equipo .accordion-item {
  border: none !important;
}

.tarjeta-equipo .accordion-button {
  border: 1px solid #27ba54 !important;
  border-radius: 10px !important;
  padding: 0.2rem;
  width: 140px;
}

.tarjeta-equipo .accordion-header {
  display: flex;
  justify-content: center;
}

.tarjeta-equipo .accordion-button::after {
  width: 1rem !important;
  height: 1rem !important;
  background-size: 1rem !important;
  margin-right: auto;
}

.tarjeta-equipo .accordion-body {
  padding: 0 !important;
  padding-top: 1rem !important;
  text-overflow: ellipsis;
}

.tarjeta-equipo .accordion-button:not(.collapse)::after {
}

@media (min-width: 992px) {
  .testimonies {
    padding: 120px 0 0;

    .__content .___testimonies-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      .tarjeta-equipo {
        width: calc(33% - 24px);
      }
    }
  }
}
</style>
